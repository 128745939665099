<template>
  <div>
    <v-toolbar elevation="1">
      <v-layout row wrap align-center class="px-4">
        <v-flex lg4>
          <v-btn outlined class="mr-2" @click="$router.push(`/academic/classrooms`)" small>
            <v-icon size="22"> mdi-keyboard-backspace </v-icon>
          </v-btn>
          <span class="mx-5 body-1 font-weight-bold"> {{ classObj.courseId ? classObj.courseId?.name : classObj.name }} - {{classObj.section}} </span>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex lg2>
          <v-autocomplete class="mt-6" :items="classObj.subjects" item-text="subjectName" item-value="subjectId" dense outlined></v-autocomplete>
        </v-flex>
        <v-flex lg1>
          <v-btn class="mx-2" tile color="primary" @click="$router.push(`config/${moduleId}`)"> Manage </v-btn>
        </v-flex>
      </v-layout>
    </v-toolbar>
    <v-card color="transparent" max-height="78vh" class="overflow-y-auto my-1" flat tile>
      <v-container fluid class="px-1 py-0">
        <v-row no-gutters>
          <v-col cols="12" lg="5" class="pa-1">
            <v-card outlined tile>
              <v-card-title>
                <v-layout>
                  <v-flex>
                    Announcements
                  </v-flex>
                  <v-flex class="text-end">
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn color="blue" v-bind="attrs" v-on="on" small dark fab class="button-small mx-1" @click="openAttendanceDialog">
                          <v-icon>mdi-ballot-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Attendance</span>
                    </v-tooltip>
                  </v-flex>
                </v-layout>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text >
                <v-alert text outlined color="deep-orange" icon="mdi-fire">
                  Under Development ...
                </v-alert>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" lg="7" class="pa-1">
            <v-card outlined tile>
              <v-card-title>
                <v-layout>
                  <v-flex>
                    Recent documents
                  </v-flex>
                  <v-flex class="text-end">
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn color="blue" v-bind="attrs" v-on="on" small dark fab class="button-small mx-1" @click="openAttendanceDialog">
                          <v-icon>mdi-ballot-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Attendance</span>
                    </v-tooltip>
                  </v-flex>
                </v-layout>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text >
                <v-alert text outlined color="deep-orange" icon="mdi-fire">
                  Under Development ...
                </v-alert>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" lg="8" class="pa-1">
            <v-card outlined tile>
              <v-card-title>
                <v-layout>
                  <v-flex>
                    Title
                  </v-flex>
                  <v-flex class="text-end">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="blue" v-bind="attrs" v-on="on" small dark fab class="button-small mx-1">
                          <v-icon>mdi-ballot-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Attendance</span>
                    </v-tooltip>
                  </v-flex>
                </v-layout>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text >
                <v-alert text outlined color="deep-orange" icon="mdi-fire">
                  Under Development ...
                </v-alert>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" lg="4" class="pa-1">
            <v-card outlined tile>
              <v-card-title>
                <v-layout>
                  <v-flex>
                    Notifications
                  </v-flex>
                  <v-flex class="text-end">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="blue" v-bind="attrs" v-on="on" small dark fab class="button-small mx-1">
                          <v-icon>mdi-ballot-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Attendance</span>
                    </v-tooltip>
                  </v-flex>
                </v-layout>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text >
                <v-alert text outlined color="deep-orange" icon="mdi-fire">
                  Under Development ...
                </v-alert>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" lg="4" class="pa-1">
            <v-card outlined tile>
              <v-card-title>
                <v-layout>
                  <v-flex>
                    Upcoming events
                  </v-flex>
                  <v-flex class="text-end">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="blue" v-bind="attrs" v-on="on" small dark fab class="button-small mx-1">
                          <v-icon>mdi-ballot-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Attendance</span>
                    </v-tooltip>
                  </v-flex>
                </v-layout>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text >
                <v-alert text outlined color="deep-orange" icon="mdi-fire">
                  Under Development ...
                </v-alert>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" lg="4" class="pa-1">
            <v-card outlined tile>
              <v-card-title>
                <v-layout>
                  <v-flex>
                    Upcoming tests/ exams
                  </v-flex>
                  <v-flex class="text-end">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="blue" v-bind="attrs" v-on="on" small dark fab class="button-small mx-1">
                          <v-icon>mdi-ballot-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Attendance</span>
                    </v-tooltip>
                  </v-flex>
                </v-layout>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text >
                <v-alert text outlined color="deep-orange" icon="mdi-fire">
                  Under Development ...
                </v-alert>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" lg="4" class="pa-1">
            <v-card outlined tile>
              <v-card-title>
                <v-layout>
                  <v-flex>
                    Available quizes
                  </v-flex>
                  <v-flex class="text-end">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="blue" v-bind="attrs" v-on="on" small dark fab class="button-small mx-1">
                          <v-icon>mdi-ballot-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Attendance</span>
                    </v-tooltip>
                  </v-flex>
                </v-layout>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text >
                <v-alert text outlined color="deep-orange" icon="mdi-fire">
                  Under Development ...
                </v-alert>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" lg="12" class="pa-1">
            <v-card outlined tile>
              <v-card-title>
                <v-layout>
                  <v-flex lg2>Students</v-flex>
                  <v-spacer></v-spacer>
                  <v-flex class="text-end">
                    <v-btn color="blue" dark tile outlined class="button-small mx-1" @click="addStudentDialog = true"> Add </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-title>
              <data-iterator :headers="payloadHeaders" :payload="payload"></data-iterator>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-dialog v-model="addStudentDialog" max-width="500px">
      <v-form ref="form">
        <v-card>
          <v-card-title class="primary white--text px-4">
            <span class="title"> Add Student </span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pt-5">
            <v-text-field outlined dense hide-details label="Search here" prepend-inner-icon="mdi-magnify" v-model="userSearch" clearable class="pt-4"></v-text-field>
            <v-list two-line subheader v-if="usersSearchResult.length">
              <v-list-item v-for="(item, index) in usersSearchResult" :key="index" link>
                <v-list-item-avatar>
                  <v-icon> mdi-account </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ item.formdata.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.formdata.email }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon> <v-icon color="grey lighten-1" @click="addToClassRoom(item._id)"> mdi-account-plus </v-icon> </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-card-text v-else class="title pt-10">
                {{ !userSearch ? 'Search Here' : 'No User Found' }}
            </v-card-text>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex"
import moment from 'moment'
export default {
  data () {
    return {
      userSearch: '',
      usersSearchResult: [],
      addStudentDialog: false,
      payload: {
        hideTop: true,
        create: false,
        items: [],
        showSelect: false,
        actionsList: [{
          click: (item) => this.removeStudentFromClassHandler(item._id),
          icon: 'mdi-close',
          title: 'Remove',
          color: 'error'
        }]
      },
      membersList: null,
      attendanceObj: {
        selected: [],
        fromMenu: false
      },
      markAllMembers: false,
      attendanceDialog: false,
      studensList: [],
      modelObj: {},
      type: '',
      moduleId: null,
      classObj: {}
    }
  },
  components: {
    'data-iterator': () => import('@/components/DataIterator.vue')
  },
  watch: {
    userSearch (value) {
      if (value) {
        let query = [{
          $lookup: {
            from: 'UserRoles',
            foreignField: '_id',
            localField: 'role',
            as: 'role'
          }
        }, {
          $unwind: {
            path: '$role',
            preserveNullAndEmptyArrays: true
          }
        }, {
          $set: {
            role: '$role.code',
            _id: { $toString: '$_id' }
          }
        }, {
          $match: {
            _id: { $nin: this.payload.items.map((item) => item._id) },
            role: 3,
            $or: [{
              'formdata.name': {
                $regex: this.userSearch,
                $options: 'i'
              },
            }, {
              'formdata.email': {
                $regex: this.userSearch,
                $options: 'i'
              }
            }]
          }
        }]
        this.$_execute('post', `users/query`, query)
          .then(({ data }) => {
            this.usersSearchResult = data
          })
      } else {
        this.usersSearchResult = []
      }
    }
  },
  mounted () {
    this.moduleId = this.$route.params.id
    this.getDetails()
    this.getStudents()
  },
  computed: {
    ...mapState(['userRoles']),
    payloadHeaders () {
      return [{
        text: 'Name',
        align: 'start',
        sortable: false,
        value: 'formdata.name'
      }, {
        text: 'Email',
        value: 'formdata.email'
      }, {
        text: 'Actions',
        value: 'actions'
      }]
    }
  },
  methods: {
    addToClassRoom (id) {
      this.$_execute('post', `classes/${this.$route.params.id}/add-students`, [{ studentId: id }])
        .then(() => {
          this.$root.$emit('snackbar', { message: 'Student added to class', color: 'success' })
          this.getStudents()
        })
    },
    getDetails () {
      let id = this.$route.params.id
      this.$_execute('get', `classes/${id}`).then(({ data }) => {
        data.subjects = data.subjects.filter(x => x.subjectId)
        data.subjects.map((x) => {
          x.subjectName = `${x.subjectId.code} - ${x.subjectId.name}`
          x.subjectId = x.subjectId._id
        })
        this.classObj = data
      })
    },
    getStudents () {
      this.$_execute('get', `classes/${this.$route.params.id}/get-students`)
        .then(({ data = [] } = {}) => {
          data = data.map((item) => item.studentId)
          this.studensList = data
          this.payload.items = data
        })
    },
    openAttendanceDialog () {
      let payload = { startdate: moment().format('YYYY-MM-DD'), enddate: moment().format('YYYY-MM-DD') }
      this.getAttendanceObj(payload)
      this.attendanceObj.datePicker =  moment().format('YYYY-MM-DD')
      this.attendanceDialog = true
    },
    getAttendanceObj (start =  this.$moment(), end = this.$moment()) {
      const query = [{
            $set: {
              class: { $toString: '$class' }
            }
          }, {
            $match: {
              $and: [
                { date: { $gte: start } },
                { date: { $lte: end } }
              ],
              // class: this.filterObj.class._id,
            }
          }]
      // if (!payload) payload = { startdate: moment(this.attendanceObj.datePicker).format('YYYY-MM-DDT00:00'), enddate: moment(this.attendanceObj.datePicker).format('YYYY-MM-DDT23:59') }
      this.$_execute('post', `studentAttendance/query`, query).then((response) => {
        if (response.data.length) {
          this.totalAttendance = response.data
          this.attendanceObj = {
            _id: response.data[0]._id,
            selected: response.data[0].presentList,
            groupId: this.selectedClassroom,
            fromMenu: false,
            // datePicker: !payload ? moment().format('YYYY-MM-DD') : moment(this.attendanceObj.datePicker).format('YYYY-MM-DD')
          }
        }
        else {
          this.attendanceObj = {
            _id: null,
            selected: [],
            fromMenu: false,
            // datePicker: !payload ? moment().format('YYYY-MM-DD') : moment(this.attendanceObj.datePicker).format('YYYY-MM-DD')
          }
        }
        this.membersList.map((x) => {
          x.isMarked = !this.attendanceObj.selected.includes(x._id)
        })
      })
    },
    saveAttendanceList () {
      this.attendanceDialog = false
      let model = {
        date: this.attendanceObj.datePicker + moment().format('THH:mm'),
        hour: this.attendanceObj.hour,
        is_present_list: this.attendanceObj.isPresentList,
        classId: this.selectedClassroom,
        totalMembers: this.studensList.total,
        list: this.attendanceObj.selected
      }
      let method = this.attendanceObj._id ? 'put' : 'post'
      let url = this.attendanceObj._id ? `attendance/${ this.attendanceObj._id }` : 'attendance'
      this.$_execute(method, url, model)
    },
    removeStudentFromClassHandler (id) {
      this.$_execute('post', `classes/${this.$route.params.id}/remove-students`, [id])
        .then(() => {
          this.getStudents()
          this.$root.$emit('snackbar', { message: 'Student removed from class', color: 'success' })
        })
    }
  }
}
</script>

<style>

</style>